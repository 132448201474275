import axios from '@/axios.js'

export default {
    getUsers: async (params) => {
        try {
            const response = await axios.adminapi.get(`v4/users`,{
                params: params
            })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },

    getUser: async (userUuid) => {
        try {
            const response = await axios.adminapi.get(`v4/users/${userUuid}`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },

    createUser: async (userUuid, data) => {
        try {
            const response = await axios.adminapi.post(`v4/users/${userUuid}`, data)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },

    editUser: async (userUuid, data) => {
        try {
            const response = await axios.adminapi.patch(`v4/users/${userUuid}`, data)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },

    deleteUser: async (userUuid) => {
        try {
            const response = await axios.adminapi.delete(`users/v3/${userUuid}`)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
}
